@import url("https://fonts.googleapis.com/css2?family=Lora&family=Montserrat:wght@300&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Montserrat", cursive;
  font-size: 2rem;
  width: 100%;
  height: 100vh;
  color: #222;
  background: url("./asset/bg.jpg");
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

@media screen and (max-width: 670px) {
  .header {
    display: none;
  }
}

.dataContainer {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 30px;
  color: #fff;
  width: 70%;
  max-width: 100%;
  gap: 4rem;
  padding: 6rem 0;
  outline-offset: 1.2rem;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 1px 4px 15px 0px rgba(0, 0, 0, 0.53);
  box-shadow: 1px 4px 15px 0px rgba(0, 0, 0, 0.53);
}

@media screen and (max-width: 1600px) {
  .dataContainer {
    width: 90%;
  }
}

.date {
  font-size: 14px;
}

.minMax {
  font-size: 14px !important;
}

h1 {
  font-weight: 400;
  font-size: 6rem;
  letter-spacing: 2px;
  word-spacing: 5px;
}

h2 {
  text-align: center;
  margin-bottom: 2.4rem;
}

.logo {
  width: 100px;
  height: auto;
}

.logoContainer {
  display: flex;
  gap: 40px;
}

.inputContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

input {
  font-family: inherit;
  color: #333;
  font-size: 2rem;
  background-color: #fff;
  padding: 1.6rem 3.2rem;
  border: none;
  width: 220px;
}

input:focus {
  outline: 2px solid #222;
}

select {
  font-family: inherit;
  color: #333;
  font-size: 2rem;
  background-color: #fff;
  padding: 1.6rem 3.2rem;
  border: none;
  width: 250px;
}

select:focus {
  outline: 2px solid #222;
}

button {
  background: #222;
  border: none;
  color: #fff;
  font-size: 2rem;
  padding: 1.6rem 3.2rem;
  border: 1px solid #222;
  cursor: pointer;
}

button:hover {
  background: #555;
}

.error {
  color: red;
  margin-top: 20px;
}

.weather {
  list-style: none;
  display: flex;
  gap: 2.4rem;
  flex-wrap: wrap;
  justify-content: center;
}

.day {
  background-color: #000;
  padding: 1.6rem 0 2rem;
  width: 12.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
  cursor: pointer;
}

.day span {
  font-size: 5.2rem;
}

.day.important {
  background-color: #ecc5c0;
  outline: 2px solid #222;
}

.loader {
  font-size: 2.4rem;
  font-weight: 700;
}

.link {
  color: #fff;
}
